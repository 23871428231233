.inactive{
    color: #9E9EA6;
}
main{
    background-color: #f5f5f5;
}

.switch{
    position: relative;
    width: 200px;
    height: 40px;
    border-radius: 25px;
}

.switch input{
    appearance: none;
    width: 200px;
    height: 40px;
    border-radius: 25px;
    background: #d2d0d0 ;
    outline: none;
}



.switch input::before,
.switch input::after{
    z-index: 2;
    position: absolute;
    top: 56%;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 10px;
}

.switch input::before{
    content: "Manually";
    left: 38px;
}

.switch input::after{
    content: "Bluk upload";
    right: 34px;
}

.switch input:checked{
    background: #d2d0d0;
}

.switch label{
    z-index: 1;
    position: absolute;
    top: 7px;
    bottom: 7px;
    border-radius: 20px;
}

.switch input{
    transition: 0.25s;
}

.switch input:checked::after,
.switch input:checked::before{
    color: #000000;
    transition: color 0.5s;
}

.switch input:checked+label{
    left: 10px;
    right: 100px;
    background: #f5f5f5;
    transition: left 0.5s, right 0.4s 0.2s;
}

.switch input:not(:checked){
    background: #d2d0d0;
    transition: background 0.4s;
}
.switch input:not(:checked)::before{
    color: #000000;
    transition: color 0.5s;
}

.switch input:not(:checked)::after{
    color: #6D5EAC ;
    transition: color 0.5s o.2s;
}
.switch input:checked::after{
    color: #000000;
    transition: color 0.5s;
}

.switch input:checked::before{
    color: #6D5EAC ;
    transition: color 0.5s o.2s;
}

.switch input:not(:checked)+label{
    left: 100px;
    right: 10px;
    background: #f5f5f5;
    transition: left 0.4s 0.2s,right 0.5s, background 0.35s;
}

